// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, ConfirmDialog, toast } from '@montugroup/design-system';
import { alternaleaf, BrandThemeProvider } from '@montugroup/themes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Chip, CircularProgress, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import React, { lazy, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { listSorter } from '@/assets/js/util';
import { formatExpirationForAnalytics, parseLuxonYearMonthDay } from '@/components/concession/utils';
import AcknowledgementModal from '@/components/PatientPageV2/components/AcknowledgementModal/AcknowledgementModal';
import { getAuthData, isDoctor } from '@/data/service/authService';
import { makeGET, makePOST, makePUT } from '@/data/service/dataService';
import { Tab } from '@/shared-ui/Tabs/Tab';
import { TabPanel } from '@/shared-ui/Tabs/TabPanel';
import { Tabs } from '@/shared-ui/Tabs/Tabs';
import { generateUUid } from '@/utils/generateUUID';
import { isWaPatient } from '@/utils/waPatient/isWaPatient';

import MedicalCondition from '@/components/Patient/forms/MedicalCondition/MedicalCondition';
import MedicalSummary from '@/components/Patient/forms/MedicalSummary/MedicalSummary';

import PatientBasicInfo from '@/components/Patient/forms/PatientBasicInfo/PatientBasicInfo';
import { IhiVerifyAndUpdateModals } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/IhiVerifyAndUpdateModals';
import { usePatientIhi } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';
import type { PatientDetailsData } from '@/hooks/patients/useGetPatientDetails';
import { PatientDetailsIncludeEnum, useGetPatientDetails } from '@/hooks/patients/useGetPatientDetails';
import { useAppStore } from '@/state-management';
import { useModal } from 'mui-modal-provider';
import { z } from 'zod';
import { ManageConcession } from '../components/concession';

import MedicalInformation from '@/components/Patient/forms/MedicalInformation/MedicalInformation';
import { FF_ENABLE_CANNABIS_NAIVE_FIELD } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useGetPatientAssessments } from '@/hooks/patients/useGetPatientAssessments';
import { useGetPatientConcessionCard } from '@/hooks/patients/useGetPatientConcessionCard';
import { useGetPatientConsultations } from '@/hooks/patients/useGetPatientConsultations';
import { useGetPatientNotes } from '@/hooks/patients/useGetPatientNotes';
import { withSuspense } from '@/shared-ui/styles/withSuspense/withSuspense';
import { DateTime } from 'luxon';
import { headings as qolHeadings } from '../components/PatientPageV2/qol';
import settings from '../data/constants';
import Mixpanel from '../utils/mixpanelServices';
import DischargePatientModal from './DischargePatientModal';

// lazy loading tab panels content
const PatientDetailsTab = withSuspense(lazy(() => import('../components/Patient/PatientDetailsTab')));
const ConsultationTab = withSuspense(lazy(() => import('../components/PatientPageV2/ConsultationTab')));
const QOL = withSuspense(lazy(() => import('../components/PatientPageV2/qol')));
const DocumentsTab = withSuspense(lazy(() => import('../components/PatientPageV2/DocumentsTab')));
const PrescriptionsTab = withSuspense(lazy(() => import('../components/PatientPageV2/PrescriptionTab')));

const API_URL = settings.url;

const CheckRequiredFieldSchema = z
  .object({
    isActive: z.literal(true),
    dischargeProgressStatus: z.literal(false),
    street_line1: z.string().min(1),
    suburb: z.string().min(1),
    postcode: z.string().min(1),
    state: z.coerce.string().min(1),
    homeDelivery: z.boolean(),
    trustedPharmacy: z.coerce.string().nullish()
  })
  .refine((data) => {
    if (!data.homeDelivery && !data.trustedPharmacy) {
      return false;
    }
    return true;
  });

const Patient = () => {
  const { id: patientId } = useParams();
  const { showModal } = useModal();

  const { search: searchParameters } = useLocation(); // could be '?foo=bar'
  const parsedParameters = new URLSearchParams(searchParameters);
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [data, setData] = useState({
    isActive: true,
    FirstName: '',
    LastName: '',
    DoB: '',
    calculatedAge: '',
    gender: '',
    atsi_status: '',
    consent: false,
    phone: '',
    email: '',
    emergency_contact_first_name: '',
    emergency_contact_last_name: '',
    emergency_contact_relationship: '',
    emergency_contact_phone_number: '',
    street: { line1: '', line2: '' },
    suburb: '',
    state: '',
    postcode: '',
    medicareExpiration: '',
    medicareNumber: '',
    HomeDelivery: false,
    trustedPharmacy: '',
    preferedPharmacy: '',

    // Medical Summary Form Data
    presentingComplaint: null,
    symptoms: null,
    trialledMedication: null,
    currentMedicationList: null,
    allergies: null,
    cardioDisease: null,
    medicalHistory: null,
    drinksPerWeek: null,
    smokingEx: '',
    // End Medical Summary Form Data

    doctorName: '',
    doctorEmail: '',
    doctorClinic: '',
    doctorPhone: '',
    detailsConfirmed: false,
    medicalSummary: false,
    PaymentVerified: false,
    PsychosisHx: false,
    DrugDrug: false,
    SubstanceAbuse: false,
    falls: false,
    Lactating: false,
    RecentlyIncarcerated: false,
    SiTosh: false,
    psycosis: '',
    DrugDrugComment: '',
    fallsComment: '',
    LactatingComment: '',
    SubstanceAbuseComment: '',
    RecentlyIncarceratedComment: '',
    SiToshComment: '',
    treatmentGoal: '',
    MCprocess: false,
    notes: '',
    patient_code: '',
    pmsDoctor: null,
    redFlag: false,
    redFlagComment: '',
    dvaNumber: '',
    ihi_number: '',
    ihi_number_status: '',
    ihi_record_status: '',
    medical_condition_1: [],
    medical_condition_2: '',
    medical_condition_3: '',
    medical_condition_4: '',
    others_1_comment: '',
    others_2_comment: '',
    patient_user_id: 0,
    discharge_status: '',
    isCurrentMedicationList: false,
    dass: null
  });
  const setIsWaPatient = useAppStore.use.setIsWaPatient();

  useEffect(() => {
    setIsWaPatient(isWaPatient(data.state));
  }, [data.state]);

  const [TabValue, setTab] = useState(isNaN(parsedParameters.get('show')) ? 0 : Number(parsedParameters.get('show')));
  const [contentChanged, setChanged] = useState(false);
  const { isAdmin, isNurse, user, isSuperAdmin, isAdminOnly, isManager } = getAuthData();
  const [showConsultation, setShowConsultation] = useState(null);
  const [consultationSubStatus, setConsultationSubStatus] = useState(null); //?  can be null | {name: string, subStatusId: number}
  const { flags } = useFeatureFlags();
  const showCannabisNaiveField = flags[FF_ENABLE_CANNABIS_NAIVE_FIELD];
  const [contents, setContents] = useState({
    doctor: {},
    patient: {},
    consultations: [],
    prescriptions: [],
    patientNotes: [],
    qol: [],
    isDoctorTransferred: {},
    isVersion2Prescription: false,
    showConsentFormResendLink: false
  });
  // this state is only used to force a refresh of the documents data.
  const [refreshDocumentsData, setRefreshDocumentsData] = useState(false);
  const [isSaving] = React.useState(false);

  const {
    data: concessionCardData,
    loading: concessionCardLoading,
    setData: setConcessionCardData
  } = useGetPatientConcessionCard(patientId);

  function forceRefreshDocumentsData() {
    setRefreshDocumentsData((prev) => !prev);
  }

  const handleChangeConcessionCard = (newCard) => {
    setContents((prevContents) => ({
      ...prevContents,
      patient: {
        ...prevContents.patient,
        // Safe to assume true as the form cannot be submitted with invalid details
        hasValidConcessionCard: true
      }
    }));
    setConcessionCardData(newCard);
    Mixpanel('Concession Card Submitted', {
      cardId: newCard.id,
      cardType: newCard.cardType,
      cardExpiry: formatExpirationForAnalytics(
        parseLuxonYearMonthDay(newCard.expiryYear, newCard.expiryMonth, newCard.expiryDay),
        newCard.cardType
      ),
      patientId: patientId,
      patientEmail: data.email
    });
    forceRefreshDocumentsData();
  };

  const isConcessionValid = contents.patient.hasValidConcessionCard;

  const [doctorDropdownList, setDoctorDropdownList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [qolData, setQol] = useState({
    created_date: [],
    commuting_ease: [],
    daily_jobs_ease: [],
    mobility_ease: [],
    self_care_ease: [],
    agitation: [],
    calm_and_tranquil: [],
    control: [],
    coping: [],
    despair: [],
    energy: [],
    sad: [],
    worry: [],
    communication_quality: [],
    hearing_quality: [],
    pain_frequency: [],
    pain_interferance: [],
    pain_level: [],
    sight_quality: [],
    Initimate_relationships: [],
    community_relationships: [],
    family_relationships: []
  });
  const [acknowledgementModalOpen, setAcknowledgementModalOpen] = useState(false);
  const [medicationData, setMedicationData] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [setSummary] = useState(true);
  const [medicalConditionDisabled, setMedicalConditionDisabled] = useState(false);

  let value;
  const emptyDassAssessmentDetails = {
    patient_id: patientId,
    link_send_count: 0,
    days_link_valid: 0,
    is_submitted: false,
    elapsed_hours: 0,
    submitted_date: ''
  };
  const emptyDassAssessmentScore = {
    anxiety: {
      score: 0,
      level: ''
    },
    depression: {
      score: 0,
      level: ''
    },
    stress: {
      score: 0,
      level: ''
    }
  };
  const [dassAssessmentDetails, setDassAssessmentDetails] = useState(emptyDassAssessmentDetails);
  const [dassAssessmentScoreDetail, setDassAssessmentScoreDetail] = useState(emptyDassAssessmentScore);
  const SearchableSelectFields = ['state', 'trustedPharmacy', 'pmsDoctor'];
  const checkboxFields = [
    'medicalSummary',
    'PaymentVerified',
    'detailsConfirmed',
    'HomeDelivery',
    'consent',
    'Lactating',
    'falls',
    'SubstanceAbuse',
    'DrugDrug',
    'PsychosisHx',
    'MCprocess',
    'RecentlyIncarcerated',
    'SiTosh'
  ];

  const [dischargeModalOpen, setDischargeModal] = React.useState(false);
  const [dischargeProgressStatus, setDischargeProgressStatus] = React.useState(false);
  const [allergies, setAllergies] = React.useState(false);
  const [cardioDisease, setCardioDisease] = React.useState(false);
  const [doctorsList, setDoctorsList] = React.useState([]);

  const handleDischargeModalClose = (status) => {
    setDischargeModal(status);
    getPatientData();
  };

  const handleDataInput = (prop) => (event) => {
    if (prop === 'DoB') {
      if (event) {
        const formattedDate = new Date(Date.UTC(event.year(), event.month(), event.date()));
        value = formattedDate.toISOString();
      } else {
        value = null;
      }
    } else if (checkboxFields.indexOf(prop) > -1) {
      value = event.target.checked;
    } else if (SearchableSelectFields.indexOf(prop) > -1) {
      value = event ? event.value : '';
    } else {
      value = event.target.value;
    }

    // If any details relating to IHI change recheck IHI
    const ihiRelatedValues = [
      'ihi_number',
      'FirstName',
      'DoB',
      'gender',
      'LastName',
      'dvaNumber',
      'medicareNumber',
      'suburb',
      'postcode',
      'state',
      'medicare_ref_number'
    ];
    if (ihiRelatedValues.includes(prop)) {
      setPatientIhiNeedsUpdate(true);
    }
    setData({
      ...data,
      [prop]: value
    });

    if (!contentChanged && prop !== 'email') {
      setChanged(true);
    }
  };

  const handleDocNotes = (val) => {
    setShowConsultation({ ...showConsultation, notes: val });
    if (!contentChanged) {
      setChanged(true);
    }
  };

  const handleAcknowledgementModalClose = () => {
    setAcknowledgementModalOpen(false);
  };

  useEffect(() => {
    const getDoctors = async () => {
      const doctors = await makeGET('doctor?raw=true');
      if (doctors) {
        setDoctorsList(doctors);
      }
    };
    getDoctors();
  }, []);

  useEffect(() => {
    if (doctorDropdownList?.length === 0) {
      makeGET('dropdown/doctor', 'getDoctors-PatientDetails').then((res) => {
        setDoctorDropdownList(res);
      });
    }
  }, [doctorDropdownList]);

  useEffect(() => {
    if (contents.qol.length > 0) {
      const qol = qolData;
      for (const entry of contents.qol) {
        qol.created_date.push(entry.created_date);
        qolHeadings.list.forEach((heading) => {
          qolHeadings[`${heading}_list`].forEach((subheading) => {
            qol[subheading].push(entry[heading][subheading]);
          });
        });
      }
      setQol(qol);
    }
  }, [contents.qol, qolData]);
  const [cursors, setCursors] = useState<{
    next: string | null;
    prev: string | null;
  } | null>(null);

  const pageParams = {
    size: 5,
    after: cursors?.next ?? undefined,
    before: cursors?.prev ?? undefined
  };

  const [notesCursors, setNotesCursors] = useState<{
    next: string | null;
    prev: string | null;
  } | null>(null);

  const notesPageParams = {
    size: 5,
    after: notesCursors?.next ?? undefined,
    before: notesCursors?.prev ?? undefined
  };
  const {
    data: patientNotes,
    isLoading: isPatientNotesLoading,
    refetch: refetchNotes
  } = useGetPatientNotes(notesPageParams, patientId);
  const [responseNotesCursors, setResponseNotesCursors] = useState<ResponseCursors>();
  const [patientNotesData, setPatientNotesData] = useState<PatientNotesData[]>();

  const handleNotesPaginationChange = ({ page: isNewPage }: PaginationModel) => {
    const cursorKey = isNewPage > 0 ? 'next' : 'prev';
    if (responseNotesCursors?.[cursorKey]) {
      if (cursorKey === 'next') {
        setNotesCursors({ prev: null, next: responseNotesCursors[cursorKey] });
      } else {
        setNotesCursors({ prev: responseNotesCursors[cursorKey], next: null });
      }
    }
  };

  useEffect(() => {
    if (patientNotes && !isPatientNotesLoading) {
      const notes = patientNotes.data.map((note) => ({
        id: note.id,
        note: note.attributes.note,
        heading: note.attributes.heading,
        strike_reason: note.attributes.strikeReason,
        created_by: note.attributes.createdBy?.userId,
        created_date: note.attributes.createdDate,
        patient_id: note.attributes.patientId,
        CreatedBy: {
          email: note.attributes.createdBy?.email,
          first_name: note.attributes.createdBy?.firstName,
          last_name: note.attributes.createdBy?.lastName
        }
      }));
      setPatientNotesData(notes);
      setResponseNotesCursors(patientNotes.meta?.page?.cursor);
    }
  }, [patientNotes, isPatientNotesLoading]);

  const handlePaginationChange = ({ page: isNewPage }: PaginationModel) => {
    const cursorKey = isNewPage > 0 ? 'next' : 'prev';

    if (cursorKey === 'next') {
      setCursors({ prev: null, next: responseCursors[cursorKey] });
    } else {
      setCursors({ prev: responseCursors[cursorKey], next: null });
    }
  };
  // TODO: needs to be refactored into a ts-rest hook
  const getPatientData = () => {
    makeGET(`patient/details/${patientId}`, 'getPatientDetails')
      .then(
        /**
         *
         * @param resp
         */
        (resp) => {
          // this won't return status 404, consider refactoring.
          if (resp && resp.status === 404) {
            navigate('/404');
          } else {
            setContents({
              consultations: resp.Consultations,
              prescriptions: resp.Prescriptions,
              doctor: resp.Doctor,
              patient: {
                ihi_number: resp.ihi_number,
                ihi_number_status: resp.ihi_number_status,
                ihi_record_status: resp.ihi_record_status,
                dob: DateTime.fromISO(resp.dob).toFormat('dd MMM yyyy'),
                medicare_number: resp.medicare_number,
                medicare_ref_number: resp.medicare_ref_number,
                allergies: resp.allergies,
                ...resp.PatientUser,
                // resp ID is the patient's ID.
                id: resp.id,
                hasValidConcessionCard: Boolean(resp.has_valid_concession_card)
              },
              dass: resp.Dass,
              qol: resp.PatientQol || [],
              patient_qol_completed_date: resp.patient_qol_completed_date
                ? DateTime.fromISO(resp.patient_qol_completed_date).toFormat('dd-MM-yyyy')
                : '',
              is_patient_qol_completed: resp.is_patient_qol_completed,
              patientNotes: resp.Notes,
              isDoctorTransferred: resp.isDoctorTransferred,
              isVersion2Prescription: resp.isVersion2Prescription,
              showConsentFormResendLink: resp.showConsentFormResend,
              propertiesNeedingAttentionBeforeOrdering: resp.propertiesNeedingAttentionBeforeOrdering
            });
            setAllergies(resp.is_allergy ? true : false);
            setCardioDisease(resp.is_cardio_disease ? true : false);
            const userAddress = data.street;
            if (resp.PatientUser.address) {
              const lineAddress = resp.PatientUser.address.split('$$$$');
              userAddress.line1 = lineAddress[0];
              userAddress.line2 = lineAddress.length > 1 ? lineAddress[1] : '';
            }

            setData((prevData) => ({
              ...prevData,
              isActive: resp.PatientUser.active,
              FirstName: resp.PatientUser.first_name,
              LastName: resp.PatientUser.last_name,
              email: resp.PatientUser.email,
              phone: resp.PatientUser.phone,
              street: userAddress,
              suburb: resp.PatientUser.city,
              state: resp.PatientUser.state_id,
              postcode: resp.PatientUser.zip_code,
              pmsDoctor: resp.doctor_id,
              medicareNumber: resp.medicare_number,
              HomeDelivery: resp.home_delivery,
              preferedPharmacy: resp.preferred_pharmacy,
              gender: resp.gender,
              trustedPharmacy: resp.pharmacy_id,
              atsi_status: resp.atsi_status,
              consent: resp.consent,
              medicareReferenceNumber: resp.medicare_ref_number,
              DoB: resp.dob ? resp.dob : '',
              calculatedAge: resp.dob
                ? Math.floor(DateTime.now().diff(DateTime.fromISO(resp.dob), ['years']).years)
                : '',
              medicareExpiration: resp.medicare_exp_date,
              presentingComplaint: resp.conditions,
              symptoms: resp.symptoms,
              trialledMedication: resp.medications,
              currentMedicationList: resp.current_medications,
              cannabisExperience: resp.tried_cannabis,
              smokingEx: resp.smoking,
              drinksPerWeek: resp.alcohol_per_week,
              cardioDisease: resp.cardio_disease,
              allergies: resp.allergies,
              psycosis: resp.psycosis,
              medicalHistory: resp.comments,
              patient_code: resp.patient_code,
              doctorName: resp.gp_name,
              doctorPhone: resp.gp_phone,
              doctorEmail: resp.gp_email,
              doctorClinic: resp.gp_clinic,
              redFlag: Boolean(resp.has_red_flag),
              redFlagComment: resp.red_flag,
              patientNote: resp.notes,
              ihi_number: resp.ihi_number,
              ihi_number_status: resp.ihi_number_status,
              ihi_record_status: resp.ihi_record_status,
              dvaNumber: resp.dva_number,
              medicare_ref_number: resp.medicare_ref_number,
              medical_condition_1: resp.medical_condition_1 ? JSON.parse(resp.medical_condition_1) : [],
              medical_condition_2: resp.medical_condition_2 ? Number(resp.medical_condition_2) : '',
              medical_condition_3: resp.medical_condition_3 ? Number(resp.medical_condition_3) : '',
              medical_condition_4: resp.medical_condition_4 ? Number(resp.medical_condition_4) : '',
              others_1_comment: resp.others_1_comment ? resp.others_1_comment : '',
              others_2_comment: resp.others_2_comment ? resp.others_2_comment : '',
              patient_user_id: resp.user_id,
              emergency_contact_first_name: resp.emergency_contact_first_name,
              emergency_contact_last_name: resp.emergency_contact_last_name,
              emergency_contact_relationship: resp.emergency_contact_relationship,
              emergency_contact_phone_number: resp.emergency_contact_phone_number,
              residential_address: resp.residential_address,
              discharge_status: resp.discharge_status
            }));
            const medicalCondition1val = resp?.medical_condition_1 ? JSON.parse(resp?.medical_condition_1) : [];

            setMedicalConditionDisabled(
              isNurse ||
                isAdmin ||
                isSuperAdmin ||
                (medicalCondition1val.length === 0 &&
                  !resp?.medical_condition_2 &&
                  !resp?.medical_condition_3 &&
                  !resp?.medical_condition_4)
                ? false
                : true
            );
            fetchDassAssessment();
            if (!resp.home_delivery) {
              setDelivery('2');
            }
          }
          setLoading(false);
        }
      )
      .catch(() => setLoading(false));
  };
  const endpointsString = [
    PatientDetailsIncludeEnum.BasicInfo,
    PatientDetailsIncludeEnum.CareInfo,
    PatientDetailsIncludeEnum.EmergencyContact,
    PatientDetailsIncludeEnum.MedicalInfo,
    PatientDetailsIncludeEnum.RedFlagInfo,
    PatientDetailsIncludeEnum.IdentificationInfo
  ].join(',');
  const { data: patientDetailsData, isLoading: isPatientDetailsLoading } = useGetPatientDetails(
    patientId,
    endpointsString
  );
  const { data: patientAssessmentsData, isLoading: isPatientAssessmentsLoading } = useGetPatientAssessments(patientId);
  const { data: patientConsultations, isLoading: isPatientConsultationsLoading } = useGetPatientConsultations(
    pageParams,
    patientId
  );
  const [responseCursors, setResponseCursors] = useState();

  const [patientConsultationsData, setPatientConsultationsData] = useState([]);
  useEffect(() => {
    if (patientConsultations && !isPatientConsultationsLoading) {
      const consultations = patientConsultations.data.map((consultation) => ({
        id: consultation.id,
        consultation_code: consultation.attributes.code,
        end_time: consultation.attributes.endTime,
        start_time: consultation.attributes.startTime,
        doctor_id: consultation.attributes.doctor?.id,
        payment_status: consultation.attributes.paymentStatus,
        paid_date: consultation.attributes.paidDate,
        consult_type: consultation.attributes.type,
        notes: consultation.attributes.notes,
        zoom_url: consultation.attributes.zoomUrl,
        status_id: consultation.attributes.consultationStatus?.id,
        sub_status_id: consultation.attributes.consultationSubStatus?.id,
        payment_queued: consultation.attributes.paymentQueued,
        calcom_consultation_uuid: consultation.attributes.calcomConsultationUuid,
        xero_consultation_code: consultation.attributes.xeroConsultationCode,
        payment_id: consultation.attributes.paymentId,
        ConsultationStatus: consultation.attributes.consultationStatus,
        ConsultationSubStatus: consultation.attributes.consultationSubStatus,
        ConsultationType: {
          type: consultation.attributes.consultationType?.type,
          duration_mins: consultation.attributes.consultationType?.durationMinutes
        },
        Doctor: consultation.attributes.doctor
          ? {
              id: consultation.attributes.doctor?.id,
              prescriber_number: consultation.attributes.doctor.prescriberNumber,
              doctor_code: consultation.attributes.doctor.doctorCode,
              calcom_initial_consult_event: consultation.attributes.doctor.calcomInitialConsultEvent,
              calcom_follow_up_consult_event: consultation.attributes.doctor.calcomFollowUpConsultEvent,
              DoctorUser: {
                first_name: consultation.attributes.doctor?.firstName,
                last_name: consultation.attributes.doctor?.lastName,
                id: consultation.attributes.doctor?.userId
              }
            }
          : null,
        Nurse: consultation.attributes.nurse
          ? {
              id: consultation.attributes.nurse?.id,
              NurseUser: {
                first_name: consultation.attributes.nurse?.firstName,
                last_name: consultation.attributes.nurse?.lastName
              }
            }
          : null
      }));
      setPatientConsultationsData(consultations);
      setResponseCursors(patientConsultations.meta?.page?.cursor);
    }
  }, [patientConsultations, isPatientConsultationsLoading]);

  useEffect(() => {
    getPatientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, patientId, qolData]);

  useEffect(() => {
    if (patientAssessmentsData && !isPatientAssessmentsLoading) {
      setData((prevData) => ({
        ...prevData,
        detailsConfirmed: patientAssessmentsData?.patientDetailsConfirmed,
        medicalSummary: patientAssessmentsData?.medicalSummaryVerified,
        PaymentVerified: patientAssessmentsData?.paymentVerified,
        DrugDrug: patientAssessmentsData?.hasDrugDrug,
        DrugDrugComment: patientAssessmentsData?.drugDrug,
        SiTosh: patientAssessmentsData?.hasSiTosh,
        SiToshComment: patientAssessmentsData?.siTosh,
        notes: patientAssessmentsData?.notes,
        MCProcess: patientAssessmentsData?.mcProcessExplained,
        treatmentGoal: patientAssessmentsData?.treatmentGoal,
        Lactating: patientAssessmentsData?.hasPregnantLactating,
        LactatingComment: patientAssessmentsData?.pregnantLactating,
        RecentlyIncarcerated: patientAssessmentsData?.hasRecentlyIncarcerated,
        RecentlyIncarceratedComment: patientAssessmentsData?.recentlyIncarcerated,
        SubstanceAbuse: patientAssessmentsData?.hasSubstanceAbuse,
        SubstanceAbuseComment: patientAssessmentsData?.substanceAbuse,
        falls: patientAssessmentsData?.hasFalls,
        fallsComment: patientAssessmentsData?.falls,
        PsychosisHx: patientAssessmentsData?.hasPsychosis
      }));
    }
  }, [patientAssessmentsData, isPatientAssessmentsLoading]);
  const {
    modalDataVerifyIhiNumber,
    modalDataSelectMultipleFields,
    modalDataChooseIhiNumber,
    modalDataUnableToVerifyIhi,
    setPatientIhiNeedsUpdate,
    handleUpdateIhiDetails,
    handleCloseModalVerifyIhiNumber,
    handleCloseModalSelectMultipleFields,
    handleCloseModalConfirmIhiNumber,
    handleCloseModalUnableToVerify,
    updateIHIData
  } = usePatientIhi({
    patientData: data,
    getPatientData,
    patientId,
    setData
  });

  const HandleTabs = (event, newValue) => {
    setTab(newValue);
  };

  /**
   * Determine if any critical medical conditions trigger the Psychological Diagnostic Assessment (PDA).
   */
  const checkIfPDAisRequired = (patientMedicalConditionData: PatientDetailsData) => {
    const defaultMedicalInfoValues = patientMedicalConditionData?.medicalInfo?.attributes;
    const medicalConditionsToCheck = [1, 2, 3];
    const medicalCondition1 = defaultMedicalInfoValues?.medicalCondition1
      ? JSON.parse(defaultMedicalInfoValues?.medicalCondition1)
      : [];

    const hasCriticalCondition1 = medicalCondition1.some((condition) => medicalConditionsToCheck.includes(condition));

    const hasCriticalOtherConditions = [
      defaultMedicalInfoValues?.medicalCondition2,
      defaultMedicalInfoValues?.medicalCondition3,
      defaultMedicalInfoValues?.medicalCondition4
    ].some((condition) => medicalConditionsToCheck.includes(Number(condition)));

    return hasCriticalCondition1 || hasCriticalOtherConditions;
  };

  const createDass = async () => {
    const sendBody = {
      patientId: patientId,
      userEmail: user?.email,
      uuid: dassAssessmentDetails?.uuid || generateUUid(),
      daysLinkValid: 5,
      patientEmail: data?.email,
      patientName: data?.FirstName
    };
    const resp = await makePOST(`dass/createDass`, sendBody, 'triggerDassAssessmentForm/createDass');
    if (resp.status === 200) {
      toast.success('Sent Dass Assessment Email');
    } else {
      toast.error('Failed to Sent Dass Assessment Email');
    }
  };

  // TODO: needs to be refactored into a ts-rest hook
  const deactivateDass = async () => {
    const sendBody = {
      patientId: patientId,
      userEmail: user?.email
    };
    await makePUT('dass/deactivateDass', sendBody, 'triggerDassAssessmentForm/deactivateDass');
  };

  const triggerDassAssessmentForm = async () => {
    if (
      (!medicalConditionDisabled && !dassAssessmentDetails?.link_send_count) ||
      dassAssessmentDetails?.link_send_count
    ) {
      const medicalCondition = checkIfPDAisRequired();
      if (medicalCondition) {
        if (!medicalConditionDisabled && !dassAssessmentDetails?.link_send_count) {
          await createDass();
        }
      } else {
        if (dassAssessmentDetails?.link_send_count) {
          await deactivateDass();
        }
      }
    }
  };

  const checkRequiredFieldSchema = async () => {
    const validateFields = CheckRequiredFieldSchema.safeParse({
      isActive: data.isActive,
      dischargeProgressStatus: dischargeProgressStatus,
      street_line1: data.street.line1,
      suburb: data.suburb,
      postcode: data.postcode,
      state: data.state,
      homeDelivery: data.HomeDelivery,
      trustedPharmacy: data.trustedPharmacy
    });

    if (!validateFields.success) {
      const error = validateFields.error.issues.map((i) => i.path.join('.')).join(', ');
      toast.error(`Unable to save the patient details, please fill in the required fields - ${error}`);
      return false;
    }
    return true;
  };

  const onSave = async (saveConsult = true) => {
    if (!checkRequiredFieldSchema) {
      return;
    }

    if (!data.isActive || dischargeProgressStatus) {
      return;
    }

    const sendBody = {
      user_details: {
        first_name: data.FirstName,
        last_name: data.LastName,
        email: data.email,
        phone: data.phone,
        address: `${data.street.line1}$$$$${data.street.line2}`,
        city: data.suburb,
        zip_code: data.postcode,
        state_id: data.state
      },
      patient_details: {
        medicare_number: data.medicareNumber,
        medicare_exp_date: data.medicareExpiration,
        medicare_ref_number: data.medicareReferenceNumber,
        dob: data.DoB,
        conditions: data.presentingComplaint,
        symptoms: data.symptoms,
        medications: data.trialledMedication,
        current_medications: data.currentMedicationList,
        tried_cannabis: data.cannabisExperience,
        smoking: data.smokingEx,
        alcohol_per_week: data.drinksPerWeek,
        allergies: data.allergies,
        cardio_disease: data.cardioDisease,
        psycosis: data.psycosis,
        comments: data.medicalHistory,
        atsi_status: data.atsi_status,
        consent: data.consent,
        gender: data.gender,
        preferred_pharmacy: data.preferedPharmacy,
        home_delivery: data.HomeDelivery,
        pharmacy_id: data.trustedPharmacy,
        gp_clinic: data.doctorClinic,
        gp_email: data.doctorEmail,
        gp_name: data.doctorName,
        gp_phone: data.doctorPhone,
        doctor_id: data.pmsDoctor,
        has_red_flag: data.redFlag,
        red_flag: data.redFlagComment,
        dva_number: data.dvaNumber,
        medical_condition_1: JSON.stringify(data.medical_condition_1),
        medical_condition_2: `${data.medical_condition_2}`,
        medical_condition_3: `${data.medical_condition_3}`,
        medical_condition_4: `${data.medical_condition_4}`,
        others_1_comment: data.others_1_comment,
        others_2_comment: data.others_2_comment,
        is_allergy: allergies,
        is_cardio_disease: cardioDisease,
        emergency_contact_first_name: data.emergency_contact_first_name,
        emergency_contact_last_name: data.emergency_contact_last_name,
        emergency_contact_relationship: data.emergency_contact_relationship,
        emergency_contact_phone_number: data.emergency_contact_phone_number
      },
      patient_assessment: {
        patient_details_confirmed: data.detailsConfirmed,
        medical_summary_verified: data.medicalSummary,
        payment_verified: data.PaymentVerified,
        drug_drug: data.DrugDrugComment,
        falls: data.fallsComment,
        pregnant_lactating: data.LactatingComment,
        substance_abuse: data.SubstanceAbuseComment,
        recently_incarcerated: data.RecentlyIncarceratedComment,
        si_tosh: data.SiToshComment,
        mc_process_explained: data.MCprocess,
        treatment_goal: data.treatmentGoal,
        has_pschosis: data.PsychosisHx,
        has_drug_drug: data.DrugDrug,
        has_substance_abuse: data.SubstanceAbuse,
        has_falls: data.falls,
        has_pregnant_lactating: data.Lactating,
        has_recently_incarcerated: data.RecentlyIncarcerated,
        has_si_tosh: data.SiTosh,
        notes: data.notes
      }
    };

    const postPatient = await savePatientDetail(`patient/${patientId}`, sendBody);

    if (!postPatient) {
      return;
    }

    await Promise.all([triggerDassAssessmentForm(), saveConsult && saveConsultation(null, false, false)]);
    toast.success('Patient Details Updated');

    setChanged(false);

    await updateIHIData();

    getPatientData();
  };

  // TODO: needs to be refactored into a ts-rest hook
  const savePatientDetail = async (url, body) => {
    try {
      const response = await axios.post(`${API_URL}/${url}`, body);
      return response.data;
    } catch (err) {
      toast.error({
        title: 'Failed to update patient details',
        message: err.response?.data?.message ?? err.message
      });
    }
  };

  // TODO: needs to be refactored into a ts-rest hook
  const saveConsultation = async (consultationData, askConfirmation = true, fetchData = true) => {
    if (!showConsultation) {
      return;
    }
    if (!askConfirmation) {
      makeSaveConsultationRequest(consultationData).then(() => {
        if (fetchData) {
          getPatientData();
        }
      });
      return;
    }
    if (consultationData.status_id === settings.consultationStatus.ineligible && !consultationData.sub_status_id) {
      toast.error({
        title: 'You must select an Ineligibility Reason',
        message: 'Please use the drop down to select the reason and then resubmit'
      });
      return;
    }

    const modal = showModal(ConfirmDialog, {
      title: 'Save Consultation',
      children:
        contents.consultations.find((x) => x.id === showConsultation.id).status_id === showConsultation.status_id
          ? 'Are you sure to proceed with the current consultation status?'
          : 'Are you sure to update the consultation status?',
      actions: (
        <>
          <Button
            onClick={async () => {
              modal.hide();
              try {
                /* Adding the try catch here instead of onSave to not modify any
                               existing functionality as its used in a few others places
                               that we are not touching at the moment. */
                await onSave(false);
              } catch (err) {
                console.error(err);
              }
              makeSaveConsultationRequest(consultationData).then(() => {
                if (fetchData) {
                  getPatientData();
                }
              });
              setShowConsultation(null);
              setConsultationSubStatus(null);
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
        </>
      )
    });
  };

  // TODO: needs to be refactored into a ts-rest hook
  const makeSaveConsultationRequest = (consultationData) =>
    makePOST(
      `consultation/${showConsultation.id}`,
      {
        data: consultationData
          ? consultationData
          : {
              id: showConsultation.id,
              notes: showConsultation.notes,
              status_id: showConsultation.status_id,
              sub_status_id: consultationSubStatus?.subStatusId
            }
      },
      'saveConsultation-PatientDetials'
    ).then((saveConsult) => {
      if (saveConsult) {
        toast.success('Consultation Saved.');
        setChanged(false);
      } else {
        toast.error({
          title: 'Failed to Save Consultation',
          message: 'Please try again later.'
        });
      }
    });

  useEffect(() => {
    if (isDoctor() && contents?.isDoctorTransferred?.status) {
      const prescriptionData = contents?.prescriptions ? listSorter(contents?.prescriptions, 'order_date') : [];
      const presData = [];
      for (const pres of prescriptionData) {
        for (const item of pres.PrescribedProducts) {
          if (item.Product && item.Product.formulation_id !== 5) {
            presData.push([
              item.created_date,
              (item.Product && item.Product.name) || item.other_product,
              item.quantity,
              item.interval,
              item.repeats
            ]);
          }
        }
      }
      setMedicationData(presData);
      if (presData.length > 0) {
        setAcknowledgementModalOpen(true);
      }
    }
  }, [contents?.prescriptions, contents?.isDoctorTransferred]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === 'medical_condition_panel') {
      setExpanded(isExpanded);
    } else if (panel === 'medical_summary_panel') {
      setSummary(isExpanded);
    }
  };

  async function fetchDassAssessmentCalculation() {
    const dassResp = await makeGET(
      `dass/dassAssessmentCalculation?patientId=${patientId}`,
      'PatientPageV2/MedicalCondition-dassAssessmentCalculation'
    );
    if (dassResp) {
      setDassAssessmentScoreDetail(dassResp);
    } else {
      setDassAssessmentScoreDetail(emptyDassAssessmentScore);
    }
  }

  // TODO: needs to be refactored into a ts-rest hook
  async function fetchDassAssessment() {
    try {
      const resp = await makeGET(
        `dass/getDass?patientId=${patientId}`,
        'PatientPageV2/MedicalCondition/fetchDassAssessment'
      );
      if (resp) {
        const patientValidityResp = await makeGET(`dass/patientDassValidity?patientId=${patientId}&uuid=${resp.uuid}`);
        setDassAssessmentDetails(patientValidityResp);

        if (resp.is_submitted) {
          fetchDassAssessmentCalculation();
        }
      } else {
        setDassAssessmentDetails(emptyDassAssessmentDetails);
        fetchDassAssessmentCalculation();
      }
    } catch (err) {
      console.log(err);
    }
  }

  // TODO: needs to be refactored into a ts-rest hook
  const handledischargePatient = async (body) => {
    if (body.dischargeReasonCode !== 'D_ACTIVE') {
      setDischargeProgressStatus(true);
    }
    const resp = await makePUT('patient/updateDischargeStatus', body, 'MergeProfile/handleDischargeSubmit');
    setDischargeProgressStatus(false);
    getPatientData();
    if (resp) {
      toast.success(`${data?.FirstName} ${data?.LastName} - ${data?.patient_code} discharged successfully`);
    } else {
      toast.error(`${data?.FirstName} ${data?.LastName} - ${data?.patient_code} discharge Failed`);
    }
  };

  // Manually update state from this data to prevent a full network request/refresh, this also prevents wiping dirty
  // fields like notes.
  function handleCreditCardHasBeenCharged(consultationId) {
    setContents({
      ...contents,
      consultations: contents.consultations.map((consult) => {
        if (consult.id === consultationId) {
          return {
            ...consult,
            payment_queued: true
          };
        }
        return consult;
      })
    });
    setShowConsultation((prevShowConsultation) =>
      prevShowConsultation
        ? {
            ...prevShowConsultation,
            payment_queued: true
          }
        : null
    );
  }

  // Manually update state from this data to prevent a full network request/refresh, this also prevents wiping dirty
  // fields like notes.
  function handleInvoiceHasBeenCreated(consultationId, invoiceCode) {
    setContents({
      ...contents,
      consultations: contents.consultations.map((consult) => {
        if (consult.id === consultationId) {
          return {
            ...consult,
            xero_consultation_code: invoiceCode
          };
        }
        return consult;
      })
    });
    setShowConsultation((prevShowConsultation) =>
      prevShowConsultation
        ? {
            ...prevShowConsultation,
            xero_consultation_code: invoiceCode
          }
        : null
    );
  }

  if (isLoading || isPatientDetailsLoading || isPatientAssessmentsLoading) {
    return (
      <Box justifyContent="center" alignItems="center" gap="1" display="flex">
        <CircularProgress size={25} />
        <Typography variant="h4">Loading</Typography>
      </Box>
    );
  }

  const consultationV3Data = {
    ...data, // note: goal is to get rid of data (which is the old api response) eventually.
    email: patientDetailsData.basicInfo?.attributes.email,
    FirstName: patientDetailsData.basicInfo?.attributes.firstName,
    LastName: patientDetailsData.basicInfo?.attributes.lastName,
    phone: patientDetailsData.basicInfo?.attributes.phone,
    redFlag: patientDetailsData.redFlagInfo.attributes.hasRedFlag,
    redFlagComment: patientDetailsData.redFlagInfo.attributes.redFlagComment
  };

  return (
    <BrandThemeProvider theme={alternaleaf}>
      {/* Remove this alert when the new patient details page is implemented */}
      <Alert severity="error">Patient Details V3</Alert>
      <Grid
        container
        paddingX={{
          sm: 1,
          md: 3,
          lg: 6
        }}
        paddingY={3}
        columnSpacing={{
          xs: 0,
          sm: 4,
          md: 7,
          lg: 7,
          xl: 8
        }}
      >
        {/* Patient medical condition and summary */}
        <Grid xs={4} item>
          <Box>
            <Box mx={1}>
              {/* "Header" */}
              <Box>
                {/** Actions: Discharge patient & Save Changes */}
                <Box
                  display="flex"
                  flexDirection={{
                    xs: 'column-reverse',
                    sm: 'column-reverse',
                    md: 'row',
                    lg: 'row'
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                  py={2}
                  gap={1}
                >
                  {!dischargeProgressStatus &&
                    data.isActive &&
                    (isNurse || isSuperAdmin || isManager) &&
                    !isAdminOnly &&
                    data?.discharge_status !== 'Active' && (
                      <>
                        <Link
                          onClick={() => setDischargeModal(true)}
                          sx={{
                            color: 'warning',
                            textDecoration: 'underline !important',
                            cursor: 'pointer',
                            fontWeight: '600',
                            fontSize: '14px'
                          }}
                        >
                          Discharge Patient
                        </Link>
                        <DischargePatientModal
                          open={dischargeModalOpen}
                          setOpen={handleDischargeModalClose}
                          data={data}
                          patient_id={patientId}
                          userId={user?.id}
                          submitDischargePatient={handledischargePatient}
                        />
                      </>
                    )}

                  <Box>
                    {data.isActive ? (
                      <Button
                        sx={{ textTransform: 'capitalize', fontSize: '14px', fontWeight: '500' }}
                        variant="contained"
                        size="medium"
                        color="secondary"
                        data-testid="saveBtn"
                        onClick={onSave}
                        disabled={isSaving || !data.isActive || dischargeProgressStatus}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Box>{data.discharge_status ? data.discharge_status : 'Inactive'}</Box>
                    )}
                    {dischargeProgressStatus ? (
                      <Box pt={0.5} fontSize={12} textAlign="center">
                        Discharge In-Progress
                      </Box>
                    ) : (
                      ''
                    )}
                    {contentChanged ? (
                      <Box pt={0.5} fontSize={8} textAlign="center" color="gray">
                        Unsaved Changes
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>

                {/** Patient Names and basic info */}
                <PatientBasicInfo isLoading={isPatientDetailsLoading} data={patientDetailsData} />
              </Box>
              {/** Concession Status */}

              <Box marginTop={1}>
                <ManageConcession
                  patientId={patientId}
                  ready={!concessionCardLoading}
                  concessionCard={concessionCardData}
                  isConcessionCardValid={isConcessionValid}
                  onChangeConcessionCard={handleChangeConcessionCard}
                />
              </Box>
              {!isAdminOnly && showCannabisNaiveField && (
                <div style={{ marginTop: '15px' }}>
                  <MedicalInformation
                    defaultValues={{ cannabisNaive: patientDetailsData?.medicalInfo?.attributes.cannabisNaive }}
                    onSubmit={(medicalSummary) => {
                      setData((current) => ({ ...current, ...medicalSummary }));
                    }}
                  />
                </div>
              )}
              {isAdminOnly && (
                <div style={{ marginTop: '15px' }}>
                  <Accordion expanded={expanded} onChange={handleChange('dass_completion_panel')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Box mt={3} p={0.5} component="h2">
                        DASS Completion
                      </Box>
                    </AccordionSummary>
                    <div>
                      {dassAssessmentDetails.is_submitted ? (
                        <Chip
                          color="success"
                          label={`DASS Completed ${dassAssessmentDetails.submitted_date && DateTime.fromISO(dassAssessmentDetails.submitted_date).toFormat('dd-MM-yyyy')}`}
                        />
                      ) : (
                        <Chip color="warning" label="DASS Not Completed" />
                      )}
                    </div>
                  </Accordion>
                </div>
              )}

              {!isAdminOnly && (
                <div style={{ marginTop: '15px' }}>
                  <MedicalCondition
                    editable={!medicalConditionDisabled && data.isActive}
                    defaultValues={patientDetailsData}
                    dassAssessmentDetails={dassAssessmentDetails}
                    dassAssessmentScoreDetail={dassAssessmentScoreDetail}
                    patientId={patientId}
                    userEmail={user?.email}
                    checkIfPDAisRequired={checkIfPDAisRequired}
                    fetchDassAssessment={fetchDassAssessment}
                    onSubmit={(medicalCondition) => {
                      setData((current) => ({ ...current, ...medicalCondition }));
                    }}
                  />
                  <MedicalSummary
                    editable={data.isActive || !dischargeProgressStatus}
                    defaultValues={patientDetailsData}
                    onSubmit={(medicalSummary) => {
                      setData((current) => ({
                        ...current,
                        ...medicalSummary,
                        presentingComplaint: medicalSummary.conditions,
                        trialledMedication: medicalSummary.medications,
                        currentMedicationList: medicalSummary.currentMedications,
                        medicalHistory: medicalSummary.comments,
                        drinksPerWeek: medicalSummary.alcoholPerWeek,
                        smokingEx: medicalSummary.smoking
                      }));
                    }}
                  />
                </div>
              )}
            </Box>
          </Box>
        </Grid>
        {/* END Patient medical condition and summary */}
        {/* Tab Panel column  */}
        <Grid item xs={8}>
          <Box pb={5}>
            <Box>
              <Tabs
                value={TabValue}
                onChange={HandleTabs}
                centered
                variant={isSmallerScreen ? 'scrollable' : 'fullWidth'}
                scrollButtons="auto"
              >
                <Tab label="Patient Details" />
                <Tab label="Consultations" />
                <Tab label="QOL" />
                <Tab label="Prescriptions" />
                <Tab label="Documentation" />
              </Tabs>
            </Box>
            <TabPanel value={TabValue} index={0}>
              <PatientDetailsTab
                setPatientIhiNeedsUpdate={setPatientIhiNeedsUpdate}
                setData={setData}
                setContents={setContents}
                data={data}
                patientDetailsData={patientDetailsData}
                contents={contents}
                isAdminOnly={isAdminOnly}
                isAdmin={isAdmin}
                isNurse={isNurse}
                isManager={isManager}
                dischargeProgressStatus={dischargeProgressStatus}
                isLoading={isLoading && isPatientDetailsLoading}
                concessionCardData={concessionCardData}
              />
            </TabPanel>
            <TabPanel value={TabValue} index={1}>
              <ConsultationTab
                onCreditCardHasBeenCharged={handleCreditCardHasBeenCharged}
                onInvoiceHasBeenCreated={handleInvoiceHasBeenCreated}
                data={{
                  consultations: patientConsultationsData,
                  patientNotes: patientNotesData
                }}
                responseCursors={responseCursors}
                fields={consultationV3Data}
                doctor={contents.doctor}
                doctorsList={doctorsList}
                handleChange={handleDataInput}
                handlePaginationChange={handlePaginationChange}
                responseNotesCursors={responseNotesCursors}
                handleNotesPaginationChange={handleNotesPaginationChange}
                refetchNotes={refetchNotes}
                patientId={patientId}
                onSave={onSave}
                patient={contents.patient}
                showConsultation={showConsultation}
                setShowConsultation={setShowConsultation}
                consultationSubStatus={consultationSubStatus}
                setConsultationSubStatus={setConsultationSubStatus}
                saveConsultation={saveConsultation}
                handleDocNotes={handleDocNotes}
                isHomeDelivery={data.HomeDelivery}
                doctorsDropdownList={doctorDropdownList}
                refresh={getPatientData}
                dischargeProgressStatus={dischargeProgressStatus}
                isLoadingConsultations={isPatientConsultationsLoading}
              />
            </TabPanel>
            <TabPanel value={TabValue} index={2}>
              {isAdminOnly &&
                (contents.is_patient_qol_completed ? (
                  <Chip color="success" label={`QOL Completed ${contents.patient_qol_completed_date}`} />
                ) : (
                  <Chip color="warning" label="QOL Not Completed" />
                ))}
              {!isAdminOnly && <QOL data={qolData} />}
            </TabPanel>

            <TabPanel value={TabValue} index={3} lazy>
              <PrescriptionsTab
                data={contents.prescriptions}
                patientActive={data.isActive}
                dischargeProgressStatus={dischargeProgressStatus}
                doctor={contents.doctor}
                patient={contents.patient}
                isHomeDelivery={data.HomeDelivery}
                isVersion2Prescription={contents.isVersion2Prescription}
                patientEmail={data.email}
                patientId={patientId}
                refresh={getPatientData}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value={TabValue} index={4}>
              <DocumentsTab
                patientId={patientId}
                refreshDocumentsData={refreshDocumentsData}
                disabled={!data.isActive || dischargeProgressStatus}
              />
            </TabPanel>
          </Box>
        </Grid>
        {/* Tab Panel column  */}
      </Grid>
      <AcknowledgementModal
        open={acknowledgementModalOpen}
        doctorName={contents?.isDoctorTransferred?.doctor_name}
        medicationData={medicationData}
        handleClose={handleAcknowledgementModalClose}
      />
      <IhiVerifyAndUpdateModals
        modalDataVerifyIhiNumber={modalDataVerifyIhiNumber}
        modalDataSelectMultipleFields={modalDataSelectMultipleFields}
        modalDataChooseIhiNumber={modalDataChooseIhiNumber}
        modalDataUnableToVerifyIhi={modalDataUnableToVerifyIhi}
        setPatientIhiNeedsUpdate={setPatientIhiNeedsUpdate}
        handleUpdateIhiDetails={handleUpdateIhiDetails}
        handleCloseModalVerifyIhiNumber={handleCloseModalVerifyIhiNumber}
        handleCloseModalSelectMultipleFields={handleCloseModalSelectMultipleFields}
        handleCloseModalConfirmIhiNumber={handleCloseModalConfirmIhiNumber}
        handleCloseModalUnableToVerify={handleCloseModalUnableToVerify}
        patientPMSData={data}
        getPatientData={getPatientData}
      />
    </BrandThemeProvider>
  );
};

export default Patient;
// split PatientDetailsv2.jsx
