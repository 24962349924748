import { jsx as o, jsxs as s } from "@emotion/react/jsx-runtime";
import u from "@mui/icons-material/CheckBox";
import a from "@mui/icons-material/CheckBoxOutlineBlank";
import m from "@mui/material/Autocomplete";
import h from "@mui/material/Checkbox";
import x from "@mui/material/TextField";
const O = /* @__PURE__ */ o(a, { fontSize: "small" }), f = /* @__PURE__ */ o(u, { fontSize: "small" });
function q({
  options: n,
  itemSelected: r,
  value: l,
  labelText: c,
  placeholderText: i,
  disabled: d
}) {
  return /* @__PURE__ */ o(
    m,
    {
      disabled: d,
      "data-testid": "nurse-multi-select",
      onChange: (e, t) => {
        r(t);
      },
      multiple: !0,
      options: n,
      disableCloseOnSelect: !0,
      value: l,
      getOptionLabel: (e) => e.title,
      isOptionEqualToValue: (e, t) => e.id === t.id,
      renderOption: (e, t, { selected: p }) => /* @__PURE__ */ s("li", { ...e, children: [
        /* @__PURE__ */ o(h, { icon: O, checkedIcon: f, sx: { mr: 1 }, checked: p }),
        t.title
      ] }),
      renderInput: (e) => /* @__PURE__ */ o(x, { ...e, label: c, placeholder: i })
    }
  );
}
export {
  q as A
};
